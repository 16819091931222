@use '@/styles/utils/mixins.scss' as *;
.socialMediaWrapper {
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleMd {
        font-size: 24px;
        line-height: 36px;
        @include tab() {
          font-size: 20px;
          line-height: 24px;
        }
      }
      &.titleSm {
        font-size: 20px;
        line-height: 24px;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
  .socialMediaSlider {
    .swiper-wrapper {
      .swiper-slide {
        width: fit-content;
        @media screen and (min-width: 768px) {
          margin-right: 40px;
        }
        @media screen and (max-width: 480px) {
          max-width: 100%;
        }
    
      }
    }
  }

  .socialMediaImg {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 1;
  }

  .socialMediaSwiperControls {
    display: flex;
    align-items: center;
    justify-content: end;
    max-width: 1280px;
    width: 100%;
    &.showNavigation {
      padding-right: 0;
      @media screen and (min-width: 1024px) {
        display: flex;
      }
      @media screen and (max-width: 576px) {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
  .socialMediaNavigation {
    display: flex;
    align-items: center;
  }
  .socialMediaSwiperNext,
  .socialMediaSwiperPrev {
    width: 24px;
    height: 24px;
    margin-left: 6px;
    svg {
      height: 100%;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      width: 48px;
      height: 48px;
    }
  }
  .socialMediaPagination {
    background-color: $keyline;
    border-radius: 15px;
    color: $kiaMidnightBlack;
    padding: 5px 10px;
    position: static;
    bottom: 0;
    right: 0;
    width: auto;
    left: auto;
    margin-right: 2px;
    line-height: 1;
    min-width: 48px;
    text-align: center;
    span {
      font-size: 12px;
      line-height: 14.4px;
    }
  }
  .socialMediaHeader {
    .socialMediaHeaderCTA {
      @media screen and (max-width: 576px) {
        display: none;
      }
    }
  }
  .socialMediaHeaderCTAmobile {
    .btnCTA {
      display: block;
    }
    @media screen and (min-width: 576px) {
      display: none;
    }
  }
}

.socialMediaNavigation {
  button.disabled {
    opacity: 0.6;
  }
}
